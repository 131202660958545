import { useEffect, useState } from 'react';
import useReleaseData from './use-release-data';
import useVehicleApprovalPage from './use-vehicle-approval-page';
import { ApproveComponentPriceProps } from '../approve-component/approve-component-price/approve-component-price.types';
import { getExpectedReferenceRate } from 'pages/car-configurator/hooks/use-info-containers-data';

const useBudgetDetailsFields = () => {
  const [budgetDetailsFields, setBudgetDetailsFields] = useState<ApproveComponentPriceProps[]>([]);

  const { vehicleOfferData } = useReleaseData();
  const { vehicleApprovalPageData } = useVehicleApprovalPage();

  useEffect(() => {
    if (vehicleApprovalPageData?.uds_leasingangeboteid && vehicleOfferData) {
      const leaseRate = getExpectedReferenceRate({
        netListPrice: vehicleApprovalPageData.uds_listenpreis_netto?.value || 0,
        leasingFactor: vehicleApprovalPageData.uds_leasingfaktor || 0,
        leaseServices: vehicleApprovalPageData.lease_services || 0
      });
      let driverSupplement = vehicleApprovalPageData.monthly_fuel_costs + leaseRate - vehicleApprovalPageData.budget;
      driverSupplement = driverSupplement > 0 ? driverSupplement : 0;
      const newBudgetDetailsFields: ApproveComponentPriceProps[] = [
        { label: 'Bruttolistenpreis inkl. Sonderausstattung:', price: vehicleOfferData.uds_listenpreis?.value || 0 },
        { label: 'Budget:', price: vehicleApprovalPageData.budget },
        { label: 'Zulässiger Eigenanteil:', price: vehicleApprovalPageData.absoluteDownPayment, bottom: '15' },
        {
          label: 'Voraussichtliche Leasingrate:',
          price: leaseRate
        },
        { label: 'Kalkulatorische Kraftstoffkosten:', price: vehicleApprovalPageData.monthly_fuel_costs },
        {
          label: 'Gesamtkosten monatlich:',
          price: vehicleApprovalPageData.monthly_fuel_costs + leaseRate,
          bottom: '15'
        },
        {
          label: 'Finaler Eigenanteil:',
          price: driverSupplement || 0
        }
      ];
      setBudgetDetailsFields(newBudgetDetailsFields);
    }
  }, [vehicleOfferData, vehicleApprovalPageData]);

  return { budgetDetailsFields };
};

export default useBudgetDetailsFields;
