import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setForm, toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS, RequestStatuses } from '../../../constants';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { useOverlay } from 'hooks';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { newKostenstelleFormFields } from 'models/forms/fahrzeug-form-model';
import Form from 'components/form/form-redux';
import { getFormValues } from 'utils/get-form-values';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { AnyObject } from 'chart.js/dist/types/basic';
import useLatestValue from 'hooks/use-latest-value';
import { createKostenstelle } from 'services/company-service';
import { primaryEntityFormFields } from 'components/cost-centers-settings/form-fields/cost-centers-primary-entity-form-fields';
import { useIsParentCompany } from 'hooks/react-query/use-is-parent-company';
import { getFields } from 'components/form/form-view';
import PrimaryEntityModal from 'components/cost-centers-settings/primary-entity-modal';
import { getResponseFormData } from 'utils/get-response-data';
import { changeMainEntity, validateMainEntity } from 'services/cost-centers-service';
import {
  CostCenterFormFieldsNames,
  PrimaryEntityValues,
  ValidateMainEntityItem,
  ValidateMainEntityResponse
} from 'components/cost-centers-settings/cost-centers-settings.props';
import PrimaryEntitySecondModal from 'components/cost-centers-settings/primary-entity-second-modal';
import { useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';

export const useCostCenterModals = (companyIdParam?: string, disableChanging?: boolean) => {
  const forms = useTypedSelector(state => state['app'].forms);
  const user = useTypedSelector(state => state['app'].user);
  const formsLatest = useLatestValue(forms);
  const dispatch = useDispatch();

  const companyId = companyIdParam || user.companyId;

  const { showRequestSnackbar } = useRequestSnackbar();
  const { isChildCompany } = useIsParentCompany(companyId);
  const [showOverlay, hideOverlay] = useOverlay();

  const [costCentersFields, setCostCentersFields] = useState(newKostenstelleFormFields);

  const queryClient = useQueryClient();

  const refetchCostCenters = () => {
    queryClient.resetQueries([PAGES.COMPANIES_BUSINESS_DETAILS, 'cost-centers-data']);
    queryClient.resetQueries([ReactQueryKeys.GetCostCenterList]);
  };

  const refetchCostCenterPrimaryEntity = () => {
    queryClient.resetQueries([PAGES.COMPANIES_BUSINESS_DETAILS, 'cost-centers-main-entity']);
  };

  useEffect(() => {
    if (isChildCompany !== undefined) {
      getFields(costCentersFields, (field: AnyObject) => {
        if (field.name === 'new_sparteid') {
          field.disabled = isChildCompany;
          field.defaultValue = isChildCompany ? companyId : '';
        }
      });

      setCostCentersFields(costCentersFields);
    }
  }, [isChildCompany]);

  const openCreateCostCenterModal = () => {
    const alertData = {
      title: 'Neue Kostenstelle anlegen',
      children: <Form name='newCostCenter' formFields={costCentersFields} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        },
        {
          type: 'submit',
          title: 'Speichern',
          action: createCostCenter
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openChangePrimaryEntityFirstModal = (fieldName: string, newValue: string) => {
    const alertData = {
      title: 'Primäre Zuordnung ändern',
      children: (
        <PrimaryEntityModal
          companyId={companyId}
          validatePrimaryEntity={isChangeChildCompaniesPrimaryEntity =>
            validatePrimaryEntity(newValue as PrimaryEntityValues, isChangeChildCompaniesPrimaryEntity)
          }
          closeModal={closeModal}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, abbrechen',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const changePrimaryEntity = async (
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    showOverlay();
    closeModal();
    let response: AxiosResponse<ResponseModel<null>> | null = null;

    try {
      response = await changeMainEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity, companyId);
    } catch (e) {
      console.log(e);
    } finally {
      hideOverlay();
    }

    showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {
      refetchCostCenterPrimaryEntity();
      refetchCostCenters();
      queryClient.clear();
    });
  };

  const validatePrimaryEntity = async (
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    showOverlay();
    closeModal();
    let response: AxiosResponse<ResponseModel<ValidateMainEntityResponse>> | null = null;

    try {
      response = await validateMainEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity, companyId);
    } catch (e) {
      console.log(e);
    } finally {
      hideOverlay();
    }

    if (response?.data.level !== RequestStatuses.Success) {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {});
      return;
    }

    const records = getResponseFormData(response.data, [
      { name: 'records', type: 'array' }
    ]) as ValidateMainEntityItem[];
    if (records.length) {
      openChangePrimaryEntitySecondModal(records, newPrimaryEntity, isChangeChildCompaniesPrimaryEntity);
    } else {
      changePrimaryEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity);
    }
  };

  const primaryEntityFields = useMemo(() => {
    const primaryEntityField = primaryEntityFormFields.find(
      field => field.name === CostCenterFormFieldsNames.PrimaryEntity
    );
    if (primaryEntityField) {
      primaryEntityField.radioButtonsOnChange = openChangePrimaryEntityFirstModal;
      primaryEntityField.disabled = !!disableChanging;
    }

    return primaryEntityFormFields;
  }, [disableChanging]);

  const openChangePrimaryEntitySecondModal = (
    records: ValidateMainEntityItem[],
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    const alertData = {
      title: 'Zuordnung ändern',
      children: (
        <PrimaryEntitySecondModal
          changePrimaryEntity={() => changePrimaryEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity)}
          closeModal={closeModal}
          records={records}
          newPrimaryEntity={newPrimaryEntity}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, abbrechen',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const createCostCenter = async () => {
    getFormValues(
      formsLatest.current,
      (form: AnyObject) => {
        dispatch(setForm(form));
      },
      async (values: AnyObject) => {
        showOverlay();
        closeModal();
        let response: AxiosResponse<ResponseModel> | null = null;

        try {
          response = await createKostenstelle(values.name, values.new_sparteid);
        } catch (e) {
          console.log(e);
        } finally {
          hideOverlay();
        }

        showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {
          refetchCostCenters();
        });
      }
    );
  };

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return { openCreateCostCenterModal, primaryEntityFields };
};
