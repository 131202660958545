/* eslint-disable no-unused-vars */
import { MenuItemProps } from 'components/more-menu/more-menu';
import { AnyObject } from 'chart.js/dist/types/basic';
import { CrmPicklistResponse, CrmLookupResponse, CrmResponse, LookupResponse } from '../../common/interfaces';
import { IFormFields } from './components/create-new-task/create-new-task.props';
import { ResponseDataItemWithId } from 'common/interfaces';
import { FileProps } from 'pages/vehicle-return-page/vehicle-return-page.props';

export enum TaskTypeId {
  Uvv = 100000000,
  Hu = 100000001,
  Allgemein = 100000002,
  Checklist = 752560000,
  Reparaturfreigabe = 100000003,
  Replacement = 100000004,
  VehicleReturn = 100000005,
  LeaseExtention = 100000006,
  Appraise = 100000007,
  ServiceProviderTasks = 752560003,
  InspectionDates = 100000008
}

export enum TaskTypeName {
  Uvv = 'UVV',
  Hu = 'HU',
  Allgemein = 'Allgemein',
  Checklist = 'Checkliste für Fahrzeugrückgabe',
  Reparaturfreigabe = 'Reparaturfreigabe',
  Replacement = 'Ersatzbeschaffung',
  LeaseExtention = 'Leasingvertragsanpassung',
  ServiceProviderTasks = 'Dienstleisteraufgaben'
}

export enum StatusName {
  Open = 'Offen',
  InProgress = 'In Bearbeitung',
  Done = 'Erledigt',
  Cancelled = 'Storniert',
  Postponed = 'Verschoben'
}

export enum StatusId {
  Open = 752560000,
  InProgress = 752560001,
  Done = 752560002,
  Cancelled = 752560003,
  Postponed = 100000002
}
export enum StatuseType {
  Overdue = 'Überfällig',
  Due = 'In den nächsten 7 Tagen fällig',
  InTheFuture = 'in zukunft'
}

export interface RoleProps {
  id: string;
  label: string;
}

export interface FilterData {
  executorContacts: ResponseDataItemWithId;
  executorFirms: ResponseDataItemWithId;
  regardingObjects: ResponseDataItemWithId;
  scheduledendStatuses: StatuseType[];
  statuses: Array<{ label: StatusName; value: StatusId }>;
  taskTypes: Array<{ label: TaskTypeName; value: TaskTypeId }>;
}

export interface TableItemProps {
  entityName: string;
  ownerid: string;
  new_name: CrmResponse;
  uds_lease_return_date: CrmResponse;
  activityid: CrmResponse;
  actualend: CrmResponse;
  description: CrmResponse;
  regardingobjectid: CrmLookupResponse;
  scheduledend: CrmResponse;
  statecode: CrmPicklistResponse;
  subject: CrmResponse;
  uds_aufgabentyp_fuhrpark: CrmPicklistResponse<TaskTypeName, TaskTypeId>;
  uds_b2c_portal_status: CrmPicklistResponse<StatusName, StatusId>;
  uds_b2cpostponedate: CrmResponse;
  uds_b2cpostponestatus: CrmPicklistResponse;
  uds_executor_accountid: CrmLookupResponse;
  uds_executor_contactid: CrmLookupResponse;
  uds_isfragesteller: CrmLookupResponse;
  new_fahrerid: CrmLookupResponse;
  new_fuhrparkid: CrmResponse;
  last_edit_date: CrmResponse;
  last_editor: CrmLookupResponse;
  lva_phaseinfo: {
    lvaId: string;
  };
  lva_state: number;
  vir_state: number;
  chkl_state: number;
  vr_state: number;
  uds_serviceid: CrmLookupResponse;
}

export interface UnfoldedTaskProps {
  aufgeklappteAufgabeInfo: TableItemProps;
  closeAufgeklappteAufgabe: () => void;
  unfoldedTaskSubmittedHandler: () => void;
  statusColor: string;
  menuItems: MenuItemProps[];
  isDriver: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  index: number;
  isEditing: boolean;
  onCancelTaskEdit: () => void;
}

export interface AufgabenItemProps {
  aufgabenInfo: TableItemProps;
  openUnfoldedTaskHandler: () => void;
  statusColor: string;
  menuItems: MenuItemProps[];
  index: number;
}

export interface NewTaskDataProps {
  [key: string]: any;
}

export interface VehicleInfoProps {
  id: string;
  keyAttributes: string[];
  logicalName: string;
  name: string;
  rowVersion: number | null;
}

export interface SpecificPhaseInfoProps {
  comment: string | null;
  mileage: number | null;
  months: number;
  phaseNo: number;
  mileagePA: number | null;
}

export interface TaskResponseItem {
  task: AnyObject[];
  taskItem: AnyObject;
  fuhrpark: AnyObject[] | null;
  leaseEndTaskInfo: {
    rvo_phase4info: {
      id: string;
      name: string;
      progressCode: number;
    }[];
    vr_phaseinfo: AnyObject | null;
    vr_state: number;
    lva_phaseinfo: {
      files: {
        Verlängerungangebot: FileProps[];
      };
      fuhrparkId: VehicleInfoProps;
      lvaId: string;
      specificPhaseInfo: SpecificPhaseInfoProps;
    };
    lva_state: number;
  };
  incident: {
    incidentid: CrmResponse;
    uds_serviceid: CrmLookupResponse;
  }[];
}

export interface TaskModelResponse {
  noAskStatuses: boolean;
  tasks: TaskResponseItem[];
  totalCount: number;
}

export interface SearchItemProps {
  id: string;
  name: string;
  isAccount: boolean;
  isFahrerRole: boolean;
  isFuhrparkleiterRole: boolean;
  logicalName: string;
  isValid: boolean;
}

export interface MobilityBudgetProps {
  modifiedon: string;
  uds_budget_eco: number;
  uds_budget_eur: number;
  uds_car_policy_nutzerid: string;
  uds_inklusivekraftstoffkosten: boolean;
  uds_isdriver_supplement_mandatory: boolean;
  uds_name: string;
  uds_zuzahlung: number;
  uds_zuzahlungtyp: number;
}

export interface CarPolicyItemProps {
  uds_carpolicyitemid: string;
  uds_name: string;
}

export interface CarPolicyProps {
  items: CarPolicyItemProps[];
  modifiedon: string;
  uds_carpolicyid: string;
  uds_name: string;
}
export enum CrmName {
  Title = 'subject',
  TaskType = 'uds_aufgabentyp_fuhrpark',
  Description = 'description',
  Employee = 'uds_executor_contactid',
  Vehicle = 'regardingobjectid',
  DueDate = 'scheduledend',
  DateOptions = 'dateOptions',
  DateChosen = 'dateChosen'
}

export interface GetVehicleForCreateTaskResponse {
  entityName: string;
  exists_tasks_type: TaskTypeId[];
  new_fahrerid: CrmLookupResponse;
  new_fuhrparkid: CrmResponse;
  new_name: CrmResponse;
  statecode: CrmPicklistResponse;
  not_allowed_for_task_types: TaskTypeId[];
}

export interface IVehicleCustomValidationArgs {
  values: {
    [key in CrmName]: string;
  };
  formFields: IFormFields;
}

export enum LeaseExtentionPhases {
  NoPhase = -1,
  Phase0 = 0, // The request is not yet to be sent, the process has not yet begun
  Phase1 = 1, // A reminder to Fuhrparkmanagement 18 weeks before the car return date.
  Phase2 = 2, // A reminder to Fleet manager 14 weeks before the car return date.
  Phase3 = 3, // Request from Fleet manager has been sent
  Phase4 = 4, // Tacitly extend - Request from Fleetmanager has been sent
  Phase5 = 5, // Fleet manager does not want to extend the contract
  Phase6 = 6, // The contract may not be extended at the decision of the Mobexo employee
  Phase7 = 7, // Sending a new offer
  Phase8 = 8, // Mobexo employee has changed the conditions and extended the contract
  Phase9 = 9, // The contract was successfully extended
  Phase10 = 10, // The contract was successfully Tacitly extended
  Phase11 = 11, // The contract renewal task was canceled by the Fleet Manager
  Phase12 = 12, // The task of renewing the contract was canceled by the Mobexo-employees.
  Phase13 = 13, // The task of Tacitly-renewing the contract was canceled by the Mobexo-employees.
  Phase14 = 14, // The offer was rejected by the Fleetmanager
  Phase15 = 15, // The new offer was successfully accepted by the Fleet Manager
  Phase16 = 16, // The contract with the new offer was successfully renewed
  // eslint-disable-next-line max-len
  Phase17 = 17, // The task with the new offer was canceled by FleetManageract with the new offer was successfully renewed
  Phase18 = 18, // The task with the notification of rejection of the offer was cancelled by Mobexo
  Phase19 = 19 // The task of notifying the agreement to a new offer has been cancelled by Mobexo
}

export interface DescriptionFromServerProps {
  description?: string;
}

export interface UpdatePhaseProps {
  nextPhase: number;
  successMessage: string;
  silentExtend?: boolean;
  mileage?: number;
  comment?: string;
  months?: number;
  silentExtendDate?: string;
  currentMileage?: number;
}

export interface UpdatePhaseWithFilesProps extends UpdatePhaseProps {
  files: File[];
}

export interface CancelationTaskPhasesProps {
  [key: number]: number;
}

export const CancelationTaskPhases: CancelationTaskPhasesProps = {
  [LeaseExtentionPhases.Phase2]: LeaseExtentionPhases.Phase11,
  [LeaseExtentionPhases.Phase3]: LeaseExtentionPhases.Phase12,
  [LeaseExtentionPhases.Phase4]: LeaseExtentionPhases.Phase13,
  [LeaseExtentionPhases.Phase7]: LeaseExtentionPhases.Phase17,
  [LeaseExtentionPhases.Phase14]: LeaseExtentionPhases.Phase18,
  [LeaseExtentionPhases.Phase15]: LeaseExtentionPhases.Phase19
};

export const RestorationTaskPhases: CancelationTaskPhasesProps = {
  [LeaseExtentionPhases.Phase11]: LeaseExtentionPhases.Phase2,
  [LeaseExtentionPhases.Phase12]: LeaseExtentionPhases.Phase3,
  [LeaseExtentionPhases.Phase13]: LeaseExtentionPhases.Phase4,
  [LeaseExtentionPhases.Phase17]: LeaseExtentionPhases.Phase7,
  [LeaseExtentionPhases.Phase18]: LeaseExtentionPhases.Phase14,
  [LeaseExtentionPhases.Phase19]: LeaseExtentionPhases.Phase15
};

export enum VehicleInitialReviewPhases {
  Phase0 = 0,
  Phase1 = 1,
  Phase2 = 2,
  Phase3 = 3,
  Phase4 = 4,
  Phase5 = 5,
  Phase6 = 6
}

export enum ChecklistPhases {
  Phase1 = 752560003,
  Phase2 = 752560000,
  Phase3 = 752560001,
  Phase4 = 752560002,
  Phase5 = 752560005,
  Phase6 = 752560006,
  Phase7 = 752560007,
  Phase8 = 752560008
}

export enum VehicleReturnPhases {
  Phase2 = 2
}
