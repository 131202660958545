import React, { MouseEvent, useEffect, useState } from 'react';
import classes from './my-vehicles.module.scss';
import { useHistory } from 'react-router-dom';
import Button from 'components/button';
import TableComponent from 'components/table-component';
import { MODALS, USER_ROLES } from '../../constants';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { TableData, TableParams } from './my-vehicles.props';
import { useOverlay, useQuery } from 'hooks';
import { useCreateQuestionModal } from 'hooks/modals/my-vehicles-create-question.modal';
import { useDispatch } from 'react-redux';
import { setForm, setSnakbar, toggleModal } from 'actions/app_action';
import { createNewTaskHandler } from 'utils/create-new-task-handler';
import { MyVehiclesFilters } from './my-vehicles-filters/my-vehicles-filters';
import { useSessionFilter } from 'hooks/use-session-filter';
import { PAGES } from 'common/enums';
import { getColums, getDefaultFilterState } from './utils';
import { MobileMyVehiclesItemComponent } from './mobile-my-vehicles-item/mobile-my-vehicles-item';
import { ButtonComponentType } from 'components/button/button.props';
import PanelPlaceholder from 'components/panel-placeholder';
import { RootTagName } from '../../components/table-component/table-component.props';
import { AnyObject } from '../../common/interfaces';
import { useMyVehicles } from '../../hooks/react-query/my-vehicles';
import { useIsUserHaveRole } from '../../hooks/use-is-user-have-role';
import useLatestValue from 'hooks/use-latest-value';
import Pagination from 'components/pagination';
import { QueryFilterData } from 'components/cost-centers-settings/primary-entity-second-modal/primary-entity-second-modal.props';
import { decodeBase64 } from 'utils/decode-encode-base64';
import { useSetFilterFromLocationState } from 'hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';
import { useFileDownload } from 'hooks/useFileDownload';
import { fleetRequestConfig } from '../../request-config/fleet/fleet.request-config';
import { useOnUnmountSetDefaultFilters } from './hooks/use-on-unmount-default-filters';
import { Flex } from 'common/common-components.styled';
import { RepeatChildren } from 'components/repeat-children/repeat-children';
import { SkeletonComponent } from 'components/skeleton/skeleton';
import useWindowSize from 'hooks/use-window-size';

const MyVehiclesPage = () => {
  const history = useHistory();
  const query = useQuery();

  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.app.user);
  const forms = useTypedSelector(state => state.app.forms);
  const formsLatest = useLatestValue(forms);

  const [tableParams, setTableParams] = useState<TableParams>(defaultTableParams);

  const [showOverlay, hideOverlay] = useOverlay();
  const { dataForServer, dataForClient } = getDefaultFilterState();
  const { sessionFilter, setSessionFilter } = useSessionFilter(PAGES.MY_VEHICLES, dataForServer, dataForClient);
  const openCreateQuestionModal = useCreateQuestionModal();

  const { table, isFetching } = useMyVehicles(tableParams, sessionFilter);

  useSetFilterFromLocationState(setSessionFilter, emtyFilterStateForServer, emtyFilterState);
  useOnUnmountSetDefaultFilters(setSessionFilter);

  const { windowWidth } = useWindowSize(500);

  const isDriverOrPoolManager = useIsUserHaveRole([USER_ROLES.driver.id, USER_ROLES.poolManager.id]);
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const queryFilterData: QueryFilterData | null = query.filterData
    ? JSON.parse(decodeBase64(query.filterData as string))
    : null;

  useEffect(() => {
    if (queryFilterData) {
      const scrollContainer = document.querySelector('.scroll-container');
      if (scrollContainer) {
        scrollContainer.scrollTo({ top: 0 });
      }

      setSessionFilter(
        {
          ...sessionFilter.filterForServer,
          [CRM_VALUE.costCenter]: queryFilterData[CRM_VALUE.costCenter]?.id || null,
          [CRM_VALUE.poolCarClass]: queryFilterData[CRM_VALUE.poolCarClass]?.id || null
        },
        {
          ...sessionFilter.filterForClient,
          [CRM_VALUE.costCenter]: queryFilterData[CRM_VALUE.costCenter]?.label || null,
          [CRM_VALUE.poolCarClass]: queryFilterData[CRM_VALUE.poolCarClass]?.label || null
        }
      );
      history.replace(PAGES.MY_VEHICLES);
    }
  }, []);

  const goToDetailsPage = (event: MouseEvent<HTMLElement>) => {
    const elem = (event.target as Element).closest('li');

    if (elem) {
      const carId = elem.getAttribute('data-car-id');
      history.push(DETAILS_PAGE_LINK + carId);
    }
  };

  const pageChangeHandler = (page: number) => {
    setTableParams(prev => ({
      ...prev,
      pagination: { ...prev.pagination, start: page }
    }));
  };

  const downloadFile = useFileDownload({
    requestConfig: fleetRequestConfig.getExcelCompany({
      ContactId: user?.id,
      Pagination: tableParams.pagination,
      Sort: tableParams.sort,
      Search: sessionFilter.filterForServer
    }),
    fileName: 'Meine_fahrzeuge.xlsx',
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob'
    }
  });

  const sortHandler = (fieldName: string) => {
    setTableParams(prevState => ({
      ...prevState,
      sort: {
        predicate: fieldName,
        reverse: fieldName === prevState.sort.predicate ? !prevState.sort.reverse : false
      }
    }));
  };

  const goToFirstPage = () => {
    setTableParams(prevState => ({
      ...prevState,
      pagination: { start: 1, number: 20 }
    }));
  };

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const snackbarSetup = (message: string, type: string) => {
    dispatch(setSnakbar({ isOpen: true, message, type }));
  };

  const openCreateQuestionModalHandler = (id: string) => {
    openCreateQuestionModal(() => {
      createNewTaskHandler({
        itemId: id,
        userId: user.id,
        closeAlert: closeAlert,
        dispatchForm: form => dispatch(setForm(form)),
        formsLatest: formsLatest.current,
        hideOverlay: hideOverlay,
        showOverlay: showOverlay,
        snackbarSetup: snackbarSetup,
        logicalName: 'new_fuhrpark'
      });
    });
  };

  const columns = getColums(openCreateQuestionModalHandler);

  return (
    <div className={classes.page_container}>
      <div className={classes.content_wrapper}>
        <div className={classes.vehicles_container}>
          <Flex className={classes.filters_container} bottom={table?.fuhrparks?.length || isFetching ? '10' : '30'}>
            <MyVehiclesFilters
              setSessionFilter={setSessionFilter}
              sessionFilter={sessionFilter || { filterForClient: {} }}
              goToFirstPage={goToFirstPage}
            />

            <div className={classes.new_vehicles}>
              {!isDriver ? (
                <Button
                  component={ButtonComponentType.DownloadExcelIcon}
                  onClick={downloadFile}
                  iconWidth={32}
                  tooltip='Download File Excel'
                />
              ) : null}

              {!isDriverOrPoolManager && (
                <Button component={ButtonComponentType.Link} to={PAGES.MY_VEHICLES_NEW_VEHICLE} width='max-content'>
                  Neues Fahrzeug anlegen
                </Button>
              )}
            </div>
          </Flex>

          {table?.fuhrparks?.length || isFetching ? (
            <div>
              {window.innerWidth <= 550 ? (
                <ul onClick={goToDetailsPage}>
                  {isFetching && (
                    <RepeatChildren
                      repeatNumber={10}
                      item={
                        <SkeletonComponent
                          color='white'
                          height={windowWidth <= 550 ? '90px' : '52px'}
                          borderRadius='10px'
                          marginBottom='10px'
                        />
                      }
                    />
                  )}
                  {table?.fuhrparks?.map(tableData => (
                    <MobileMyVehiclesItemComponent key={tableData.new_fuhrparkid} data={tableData} />
                  ))}
                </ul>
              ) : (
                <TableComponent
                  loading={isFetching}
                  columns={columns}
                  rows={table.fuhrparks}
                  rootTag={{
                    name: RootTagName.Link,
                    to: DETAILS_PAGE_LINK,
                    propName: 'new_fuhrparkid'
                  }}
                  sort={sortHandler}
                  sortParams={tableParams.sort}
                />
              )}

              <div className='mt-30' />
            </div>
          ) : isDriver && !isFetching ? (
            <PanelPlaceholder
              title='Du hast noch kein Auto'
              description='Bitte kontaktiere das interne Fuhrparkmanagement mit der Bitte,
              dein Fahrzeug anzulegen oder dich ggf. an einem Bestandsfahrzeug einzutragen.'
              img={'/assets/images/nothing_found_driver.svg'}
            />
          ) : (
            <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
          )}
        </div>
      </div>
      {table.totalCount > tableParams.pagination.number && (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table.totalCount}
          onPageChange={pageChangeHandler}
        />
      )}
    </div>
  );
};

export default MyVehiclesPage;

const defaultTableParams = {
  pagination: {
    start: 1,
    number: 20
  },
  sort: {},
  search: {}
};

export const CRM_VALUE = {
  company: 'new_sparteid',
  car: 'new_name',
  carNumber: 'uds_modexocarinternalid',
  driver: 'new_fahrerid',
  firstRegistration: 'new_ezl',
  manufacturer: 'new_hersteller',
  leaseReturn: 'uds_lease_return_date',
  model: 'new_model',
  status: 'portalStatuses',
  stateCode: 'statecode',
  costCenter: 'new_kostenstelleid',
  location: 'new_standortid',
  mileageRecommendation: 'uds_mileagerecommendation',
  consumption: 'uds_consumption_estimate_value',
  leasingContractNumber: 'new_lvn',
  poolCarClass: 'uds_poolcar_classid',
  qualityData: 'uds_date_quality_statecode'
};

export const EXPANDED_FILTERS = [
  CRM_VALUE.costCenter,
  CRM_VALUE.firstRegistration,
  CRM_VALUE.carNumber,
  CRM_VALUE.location,
  CRM_VALUE.mileageRecommendation,
  CRM_VALUE.leasingContractNumber,
  CRM_VALUE.consumption
];

export const emtyFilterState = {
  [CRM_VALUE.firstRegistration]: null,
  [CRM_VALUE.leaseReturn]: null,
  [CRM_VALUE.driver]: null,
  [CRM_VALUE.manufacturer]: null,
  [CRM_VALUE.model]: null,
  [CRM_VALUE.car]: null,
  [CRM_VALUE.company]: null,
  [CRM_VALUE.status]: [],
  [CRM_VALUE.stateCode]: [],
  [CRM_VALUE.carNumber]: null,
  [CRM_VALUE.costCenter]: null,
  [CRM_VALUE.location]: null,
  [CRM_VALUE.mileageRecommendation]: null,
  [CRM_VALUE.consumption]: null,
  [CRM_VALUE.leasingContractNumber]: null,
  [CRM_VALUE.poolCarClass]: null
};

export const emtyFilterStateForServer: AnyObject = {
  ...emtyFilterState,
  invoicedate: { startdate: null, enddate: null },
  uds_lease_return_date: { startdate: null, enddate: null }
};

export const defaultTableData: TableData = {
  fuhrparks: [],
  totalCount: 0
};

export const DETAILS_PAGE_LINK = `${PAGES.MY_VEHICLES_DETAILS}/?id=`;
