import React from 'react';
import { USER_ROLES } from '../../constants';
import { PAGES, UNAUTHORIZED_PAGES } from 'common/enums';
import { MainMenuItem } from 'components/side-menu/side-menu-interfaces';
import { Car } from 'components/icons-new/car';
import { MainMenuTitles, SubMainMenuTitles } from 'components/side-menu/side-menu-enums';
import { DashboardIcon } from 'components/icons-new/dashboard';
import { HomeIcon } from 'components/icons-new/home';
import { DocumentApprovedIcon } from 'components/icons-new/document-approved';
import { BillIcon } from 'components/icons-new/bill';
import { ShoppingCart } from 'components/icons-new/shopping-cart';
import { UsedCar } from 'components/icons-new/used-car';
import { ArchiveIcon } from 'components/icons-new/archive';
import { SettingsIcon } from 'components/icons-new/settings';
import { ContractIcon } from 'components/icons-new/contract';
import { CustomersIcon } from 'components/icons-new/customers';
import { EmailIcon } from 'components/icons-new/email';
import { CarRepairIcon } from 'components/icons-new/car-repair';
import { DamagedCarIcon } from 'components/icons-new/damaged-car';
import { DriversLicenseIcon } from 'components/icons-new/drivers-license';
import { GasStationIcon } from 'components/icons-new/gas-station';
import { FinishIcon } from 'components/icons-new/finish';
import { FuelCardIcon } from 'components/icons-new/fuel-card';
import { CarCheckedIcon } from 'components/icons-new/car-checked';
import { CalendarCheckedIcon } from 'components/icons-new/calendar-checked';
import { CalendarPlanningIcon } from 'components/icons-new/calendar-planning';
import { WalletIcon } from 'components/icons-new/wallet';
import { CalculatorIcon } from 'components/icons-new/calculator';
import { NewCarIcon } from 'components/icons-new/new-car';
import { CarPriceIcon } from 'components/icons-new/car-price';
import { EnterpriceIcon } from 'components/icons-new/enterprice';
import { FinnIcon } from 'components/icons-new/finn';
import { CompanyIcon } from 'components/icons-new/company';
import { DriverLevelIcon } from 'components/icons-new/driver-level';
import { TransmissionIcon } from 'components/icons-new/transmission';
import { GearIcon } from 'components/icons-new/gear';
import { FileUploadIcon } from 'components/icons-new/file-upload';
import { UserIcon } from 'components/icons-new/user';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { NewDriverIcon } from 'components/icons-new/new-driver';
import { DocumentIcon } from 'components/icons-new/document';
import TopDealsIcon from 'components/icons-new/top-deals';
import { CheckedIcon } from 'components/icons-new/checked-icon';
import { CarWithGearIcon } from 'components/icons-new/car-with-gear';
import { EuroSignWithBorderIcon } from 'components/icons-new/euro-sign-with-border';
import { CarWithBorderIcon } from 'components/icons-new/car-with-border';

export const MAIN_MENU_ITEMS: MainMenuItem[] = [
  {
    menuTitle: MainMenuTitles.Dashboard,
    links: [PAGES.DASHBOARD],
    expand: false,
    mainMenuImg: <DashboardIcon />,
    roles: [],
    subMenuItems: []
  },
  {
    menuTitle: MainMenuTitles.Vehicle,
    expand: false,
    mainMenuImg: <Car />,
    subMenuItems: [
      {
        links: [PAGES.REPAIRED],
        title: SubMainMenuTitles.RepairStatus,
        roles: [],
        subMenuImg: <CarRepairIcon />
      },
      {
        links: [UNAUTHORIZED_PAGES.REPORT_DAMAGE],
        title: SubMainMenuTitles.ReportDamage,
        external: true,
        roles: [],
        subMenuImg: <DamagedCarIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Fleet,
    expand: false,
    mainMenuImg: <HomeIcon />,
    subMenuItems: [
      {
        links: [PAGES.MY_DRIVERS, PAGES.MY_DRIVERS_CREATE, PAGES.MY_DRIVERS_DETAILS],
        title: SubMainMenuTitles.Driver,
        roles: [],
        subMenuImg: <CustomersIcon />
      },
      {
        links: [PAGES.DRIVERS_CHECK],
        title: SubMainMenuTitles.DriverLicenseCheck,
        roles: [],
        subMenuImg: <DriversLicenseIcon />
      },
      {
        links: [PAGES.MY_VEHICLES, PAGES.MY_VEHICLES_DETAILS, PAGES.MY_VEHICLES_NEW_VEHICLE],
        title: SubMainMenuTitles.Vehicles,
        roles: [],
        subMenuImg: <Car />
      },
      {
        links: [PAGES.DAMAGE, PAGES.DAMAGE_DETAILS],
        title: SubMainMenuTitles.Damage,
        roles: [],
        subMenuImg: <DamagedCarIcon />
      },
      {
        links: [PAGES.FUEL_REPORT],
        title: SubMainMenuTitles.FuelReport,
        roles: [],
        subMenuImg: <GasStationIcon />
      },
      {
        links: [PAGES.FUEL_CARDS, PAGES.FUEL_CARDS_ORDER, PAGES.FUEL_CARDS_ORDER_FUEL_CARD],
        title: SubMainMenuTitles.FuelCards,
        roles: [],
        subMenuImg: <FuelCardIcon />
      },
      {
        links: [
          PAGES.ASSIGNMENTS,
          PAGES.ASSIGNMENTS_VEHICLE_TRANSFER,
          PAGES.ASSIGNMENTS_VEHICLE_TRANSFER_RECORD,
          PAGES.ASSIGNMENTS_ORDER_RENTAL_CAR,
          PAGES.ASSIGNMENTS_ACCIDENT_REPAIR,
          PAGES.ASSIGNMENTS_ACCIDENT_REPAIR_RECORD,
          PAGES.ASSIGNMENTS_LEASE_RETURN,
          PAGES.ASSIGNMENTS_LEASE_RETURN_RECORD,
          PAGES.ASSIGNMENTS_FUEL_RECEIPT_SUBMIT,
          PAGES.ASSIGNMENTS_ELECTRIC_CAR_CHARGING_COST
        ],
        title: SubMainMenuTitles.Assignments,
        roles: [],
        subMenuImg: <DocumentIcon />
      },
      {
        links: [PAGES.POOL_CALENDAR, PAGES.POOL_CALENDAR_NEW, PAGES.POOL_CALENDAR_VEHICLE],
        title: SubMainMenuTitles.PoolCarBookings,
        roles: [],
        subMenuImg: <CarCheckedIcon />
      },
      {
        links: [PAGES.MY_POOL_CALENDAR, PAGES.MY_POOL_CALENDAR_NEW, PAGES.MY_POOL_CALENDAR_VEHICLE],
        title: SubMainMenuTitles.MyPoolCarBookings,
        roles: [],
        subMenuImg: <CarCheckedIcon />
      },

      // {
      //   links: [PAGES.LONG_TERM_RENTAL],
      //   title: SubMainMenuTitles.LongTermRental,
      //   roles: [],
      //   subMenuImg: <Car />
      // },
      {
        links: [PAGES.LEASE_END],
        title: SubMainMenuTitles.LeaseEnd,
        roles: [],
        subMenuImg: <FinishIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Tasks,
    expand: false,
    mainMenuImg: <DocumentApprovedIcon />,
    subMenuItems: [
      {
        links: [PAGES.TASKS],
        title: SubMainMenuTitles.Tasks,
        roles: [],
        subMenuImg: <CalendarCheckedIcon />
      },
      {
        links: [PAGES.SERVICE_PROVIDER_TASKS],
        title: SubMainMenuTitles.ServiceProvider,
        roles: [],
        subMenuImg: <CalendarPlanningIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Accounting,
    expand: false,
    mainMenuImg: <BillIcon />,
    subMenuItems: [
      {
        links: [PAGES.VEHICLE_COSTS],
        title: SubMainMenuTitles.VehicleCost,
        roles: [],
        subMenuImg: <WalletIcon />
      },
      {
        links: [PAGES.MONTHLY_BILLS],
        title: SubMainMenuTitles.MonthlyBills,
        roles: [],
        subMenuImg: <BillIcon />
      },
      {
        links: [PAGES.BILLING_ELECTRIC_CAR_CHARGING_COSTS],
        title: SubMainMenuTitles.WallBoxBilling,
        roles: [],
        subMenuImg: <CalculatorIcon />
      },
      {
        links: [PAGES.TANK_RECEIPT_SUBMIT],
        title: SubMainMenuTitles.SubmitFuelReceipt,
        roles: [],
        subMenuImg: <GasStationIcon />
      },
      {
        links: [
          PAGES.EINGANGSRECHNUNG,
          PAGES.EINGANGSRECHNUNG_EDIT,
          PAGES.EINGANGSRECHNUNG_CREATE,
          PAGES.EINGANGSRECHNUNG_UPLOAD
        ],
        title: SubMainMenuTitles.IncomingInvoices,
        indicator: true,
        indicatorText: 'Diese Eingangsrechnungen warten auf Deine Überprüfung.',
        roles: [],
        subMenuImg: <BillIcon />
      },
      {
        links: [PAGES.DOCUMENTMANAGER, PAGES.DOCUMENTMANAGER_UPLOAD, PAGES.DOCUMENTMANAGER_EDIT],
        title: SubMainMenuTitles.DocumentManager,
        indicator: true,
        indicatorText: 'Diese Dokumente sind noch nicht sortiert worden.',
        roles: [],
        subMenuImg: <DocumentIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Orders,
    expand: false,
    mainMenuImg: <ShoppingCart />,
    subMenuItems: [
      {
        links: [PAGES.CONFIGURATIONS, PAGES.NEW_CONFIGURATIONS, PAGES.CONFIGURATION_DETAILS],
        title: SubMainMenuTitles.Configurations,
        roles: [],
        subMenuImg: <NewCarIcon />
      },
      {
        links: [
          PAGES.EMPLOYEE_CONFIGURATIONS,
          PAGES.NEW_REFERENCE_CONFIGURATIONS,
          PAGES.EMPLOYEE_CONFIGURATION_DETAILS,
          PAGES.REFERENCE_VEHICLES_DETAILS
        ],
        title: SubMainMenuTitles.EmployeeConfigurations,
        roles: [],
        subMenuImg: <CarPriceIcon />
      },
      {
        links: [PAGES.ORDERS, PAGES.ORDER_DETAILS],
        title: SubMainMenuTitles.Orders,
        roles: [],
        underline: true,
        subMenuImg: <ShoppingCart />
      },
      {
        links: [PAGES.GLOBAL_REFERENCE_VEHICLES],
        title: SubMainMenuTitles.Cockpit,
        roles: [],
        subMenuImg: <CarWithGearIcon />
      },
      {
        links: [PAGES.COCKPIT_2_0],
        title: SubMainMenuTitles.Cockpit_2_0,
        roles: [],
        subMenuImg: <CarWithGearIcon />
      },
      {
        links: [PAGES.APPROVALS, PAGES.APPROVALS_DETAILS],
        title: SubMainMenuTitles.Approvals,
        roles: [],
        subMenuImg: <CheckedIcon />
      },
      {
        links: [PAGES.TOP_DEALS],
        title: SubMainMenuTitles.TopDeals,
        roles: [],
        subMenuImg: <TopDealsIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.BookRentalCar,
    expand: false,
    mainMenuImg: <UsedCar />,
    subMenuItems: [
      {
        links: [UNAUTHORIZED_PAGES.ENTERPRISE],
        external: true,
        title: SubMainMenuTitles.Enterprise,
        roles: [],
        subMenuImg: <EnterpriceIcon />
      },
      {
        links: [PAGES.BOOKING_RENTAL_CAR],
        title: SubMainMenuTitles.Enterprise,
        roles: [],
        subMenuImg: <EnterpriceIcon />
      },
      {
        links: [UNAUTHORIZED_PAGES.FINN],
        external: true,
        title: SubMainMenuTitles.Finn,
        roles: [],
        subMenuImg: <FinnIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Requests,
    expand: false,
    mainMenuImg: <ArchiveIcon />,
    subMenuItems: [
      {
        links: [PAGES.MY_REQUESTS],
        title: SubMainMenuTitles.MyRequests,
        roles: [],
        subMenuImg: <ArchiveIcon />
      },
      {
        links: [PAGES.REQUESTS, PAGES.REQUESTS_DETAILS],
        title: SubMainMenuTitles.VehicleRequests,
        roles: [],
        subMenuImg: <NewCarIcon />
      },
      {
        links: [PAGES.LEASING_REQUESTS, PAGES.LEASING_REQUESTS_DETAILS],
        title: SubMainMenuTitles.LeasingRequests,
        roles: [],
        subMenuImg: <CarPriceIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Settings,
    expand: false,
    mainMenuImg: <SettingsIcon />,
    subMenuItems: [
      {
        links: [
          PAGES.COMPANIES_BUSINESS_DETAILS,
          PAGES.COMPANIES_CONTACT,
          PAGES.COMPANIES_COMPANY,
          PAGES.COMPANIES_LEASING,
          PAGES.COMPANIES_ORDER_CONTROL
        ],
        title: SubMainMenuTitles.Companies,
        roles: [],
        subMenuImg: <CompanyIcon />
      },
      {
        links: [
          PAGES.FLEET_SETTINGS_NOTIFICATION,
          PAGES.FLEET_SETTINGS_POOL_CALENDAR,
          PAGES.FLEET_SETTINGS_CONSUMPTION_SURCHARGE,
          PAGES.FLEET_SETTINGS_INSPECTION_DATES
        ],
        title: SubMainMenuTitles.FleetSettings,
        roles: [],
        subMenuImg: <CarWithBorderIcon />
      },
      {
        links: [
          PAGES.ACCOUNTING_SETTINGS_PAYMENT,
          PAGES.ACCOUNTING_SETTINGS_LEDGER_ACCOUNT_MAPPING,
          PAGES.ACCOUNTING_SETTINGS_COST_CENTERS
        ],
        title: SubMainMenuTitles.AccountingSettings,
        roles: [],
        underline: true,
        subMenuImg: <EuroSignWithBorderIcon />
      },
      {
        links: [PAGES.MOBILITY_BUDGET],
        title: SubMainMenuTitles.Budget,
        roles: [],
        subMenuImg: <DriverLevelIcon />
      },
      {
        links: [PAGES.CAR_POLICY],
        title: SubMainMenuTitles.CarPolicy,
        roles: [],
        subMenuImg: <TransmissionIcon />
      },
      {
        links: [PAGES.REFERENCE_VEHICLES, PAGES.NEW_CONFIGURATION],
        title: SubMainMenuTitles.ReferenceVehicle,
        roles: [],
        underline: true,
        subMenuImg: <CarCheckedIcon />
      },
      {
        links: [PAGES.DOCUMENTS, PAGES.DOCUMENTS_CONTRACT],
        title: SubMainMenuTitles.Documents,
        roles: [],
        subMenuImg: <ContractIcon />
      },
      {
        links: [PAGES.CONTRACTS],
        title: SubMainMenuTitles.Contracts,
        roles: [],
        underline: true,
        subMenuImg: <DocumentIcon />
      },
      {
        links: [PAGES.SETUP_ASSISTANT],
        title: SubMainMenuTitles.SetupAssistant,
        roles: [],
        subMenuImg: <GearIcon />
      },
      {
        links: [PAGES.IMPORT],
        title: SubMainMenuTitles.Import,
        roles: [],
        subMenuImg: <FileUploadIcon />
      },
      {
        links: [PAGES.PERSONAL_EMAIL_TEMPLATE],
        title: SubMainMenuTitles.PersonalEmailTemplate,
        roles: [],
        subMenuImg: <EmailIcon />
      },

      {
        links: [PAGES.ACCOUNT_SETTINGS],
        hideIfParams: true,
        title: SubMainMenuTitles.UserSettings,
        roles: [],
        subMenuImg: <UserIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.BecomeCustomer,
    expand: false,
    mainMenuImg: <ContractIcon />,
    subMenuItems: [
      {
        links: [PAGES.PACKAGES, PAGES.PACKAGE_PAGE, PAGES.ADD_COMPANY],
        title: SubMainMenuTitles.PackagesAndPrice,
        roles: [],
        subMenuImg: <ShoppingCart />,
        showWhenSiteUnavailable: true
      },
      {
        links: [PAGES.DECISION_SUPPORT],
        title: SubMainMenuTitles.DecisionSupport,
        roles: [],
        subMenuImg: <InfoHintIcon />,
        showWhenSiteUnavailable: true
      },
      {
        links: [PAGES.CLIENTS_FLEETMANAGER, PAGES.INVITE_CLIENT_FLEETMANAGER],
        title: SubMainMenuTitles.CustomersRecruitCustomers,
        roles: [],
        subMenuImg: <NewDriverIcon />,
        showWhenSiteUnavailable: true
      },
      {
        links: [PAGES.CREATE_OFFER, PAGES.CREATE_OFFER_PROCESS],
        title: SubMainMenuTitles.CreateOffer,
        roles: [],
        subMenuImg: <BillIcon />,
        showWhenSiteUnavailable: true
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Customers,
    expand: false,
    mainMenuImg: <CustomersIcon />,
    subMenuItems: [
      {
        links: [PAGES.CLIENTS_DEALER],
        title: SubMainMenuTitles.Customers,
        roles: [],
        subMenuImg: <CustomersIcon />
      },
      {
        links: [PAGES.INVITE_CLIENT_DEALER, PAGES.INVITE_CLIENT_DEALER],
        title: SubMainMenuTitles.InviteCustomers,
        roles: [],
        subMenuImg: <NewDriverIcon />
      }
    ]
  },
  {
    menuTitle: MainMenuTitles.Contact,
    links: [PAGES.SUPPORT],
    expand: false,
    mainMenuImg: <EmailIcon />,
    roles: [],
    subMenuItems: []
  },
  {
    menuTitle: MainMenuTitles.APIDoc,
    links: [PAGES.SWAGGER],
    expand: false,
    mainMenuImg: <DocumentApprovedIcon />,
    roles: [],
    subMenuItems: []
  }
];
