import { MenuTitles, SettingsMenuItems } from 'hooks/use-settings-menu-items/use-settings-menu-items.types';
import { crmDataTypes } from '../../../constants';
import { PAGES } from 'common/enums';
import { User } from 'common/interfaces';

export const getCompanySettingsDefaultMenuItems = (user: User): SettingsMenuItems[] => {
  const userRole = user.mainRole.id;
  const items = [
    {
      title: MenuTitles.BusinessDetails,
      page: PAGES.COMPANIES_BUSINESS_DETAILS,
      active: false
    },
    {
      title: MenuTitles.Contact,
      page: PAGES.COMPANIES_CONTACT,
      active: false
    },
    {
      title: MenuTitles.Company,
      page: PAGES.COMPANIES_COMPANY,
      active: false
    },
    {
      title: MenuTitles.Leasing,
      page: PAGES.COMPANIES_LEASING,
      active: false
    },
    {
      title: MenuTitles.OrderControl,
      page: PAGES.COMPANIES_ORDER_CONTROL,
      active: false
    }
  ];

  return items.filter(
    item => item.page in user.appPages && user.appPages[item.page].some(role => role.uds_b2c_webroleid === userRole)
  );
};

export const mileageRangeOptions = {
  uds_lease_annual_milleage_k_10: {
    value: 10000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_15: {
    value: 15000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_20: {
    value: 20000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_25: {
    value: 25000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_30: {
    value: 30000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_35: {
    value: 35000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_40: {
    value: 40000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_45: {
    value: 45000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_50: {
    value: 50000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_55: {
    value: 55000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_60: {
    value: 60000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_65: {
    value: 65000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_70: {
    value: 70000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_75: {
    value: 75000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_80: {
    value: 80000,
    attributeTypeCode: crmDataTypes.Boolean
  }
};

export const durationOptions = {
  uds_lease_length_month_12: {
    label: '12'
  },
  uds_lease_length_month_18: {
    label: '18'
  },
  uds_lease_length_month_24: {
    label: '24'
  },
  uds_lease_length_month_30: {
    label: '30'
  },
  uds_lease_length_month_36: {
    label: '36'
  },
  uds_lease_length_month_42: {
    label: '42'
  },
  uds_lease_length_month_48: {
    label: '48'
  },
  uds_lease_length_month_54: {
    label: '54'
  },
  uds_lease_length_month_60: {
    label: '60'
  }
};
